import { gl } from './main';
import satSet from './sat';
import Groups from '../Groups';

const ColorScheme = function (colorizer) {
	this.colorizer = colorizer;
	this.colorBuf = gl.createBuffer();
	this.pickableBuf = gl.createBuffer();
};

ColorScheme.prototype.calculateColorBuffers = function () {
	const { numSats } = satSet;
	const colorData = new Float32Array(numSats * 4);
	const pickableData = new Float32Array(numSats);
	for (let i = 0; i < numSats; i++) {
		const colors = this.colorizer(i);
		colorData[i * 4] = colors.color[0]; // R
		colorData[i * 4 + 1] = colors.color[1]; // G
		colorData[i * 4 + 2] = colors.color[2]; // B
		colorData[i * 4 + 3] = colors.color[3]; // A
		pickableData[i] = colors.pickable ? 1 : 0;
	}
	gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuf);
	gl.bufferData(gl.ARRAY_BUFFER, colorData, gl.STATIC_DRAW);
	gl.bindBuffer(gl.ARRAY_BUFFER, this.pickableBuf);
	gl.bufferData(gl.ARRAY_BUFFER, pickableData, gl.STATIC_DRAW);
	return {
		colorBuf: this.colorBuf,
		pickableBuf: this.pickableBuf,
	};
};

ColorScheme.init = function () {
	ColorScheme.default = new ColorScheme(function (satId) {
		const sat = satSet.getSat(satId);
		let color;
		switch (sat.REGIME) {
			case 'Low Earth Orbit': // RED
				color = [1, 0.2, 0, 1];
				break;
			case 'Medium Earth Orbit': // GREEN
				color = [0.2, 1, 0.0, 1];
				break;
			case 'Geosynchronous': // cyan 00ffff
				color = [0.0, 1, 1, 1];
				break;
			case 'Elliptical': // YELLOW
			case 'Molniya':
				color = [1, 1, 0.2, 1];
				break;
			case 'Geostationary': // PURPLE
				color = [(1 / 255) * 192, (1 / 255) * 136, 1, 1];
				break;
			default:
				// PINK
				color = [1, 0.5, 0.6, 1];
		}
		return {
			color,
			pickable: true,
		};
	});

	ColorScheme.group = new ColorScheme(function (satId) {
		if (Groups.selectedGroup.hasSat(satId)) {
			return {
				color: [1.0, 0.2, 0.0, 1.0],
				pickable: true,
			};
		}
		return {
			color: [1.0, 1.0, 1.0, 0.1],
			pickable: false,
		};
	});
};

export default ColorScheme;
