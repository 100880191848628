import React from 'react';
import satSet from './scripts/sat';
import Group from './Group';
import { selectSat } from './scripts/main';
import searchBox from './scripts/searchBox';
import ColorScheme from './scripts/colorScheme';

export function initializeGroups() {
	const groupedSatellites = satSet.satData.reduce(
		(prevGroups, satellite, index) => {
			const newGroups = { ...prevGroups };
			const groupHandle = satellite['LAUNCH SITE'].trim();
			if (!newGroups[groupHandle]) {
				newGroups[groupHandle] = [index];
			} else {
				newGroups[groupHandle].push(index);
			}

			return newGroups;
		},
		{}
	);

	const classedSatellites = {};
	Object.entries(groupedSatellites)
		.sort((groupA, groupB) => groupA[0] > groupB[0])
		.forEach(([key, value]) => {
			classedSatellites[key] = new Group(value);
		});

	window.setGroups(classedSatellites);
	return classedSatellites;
}

export default class Groups extends React.Component {
	state = {
		isClicked: false,
		isClearVisible: false,
		isMenuVisible: false,
		selectedGroupName: null,
	};

	static selectedGroup = [];

	static selectGroup = (group) => {
		Groups.selectedGroup = group;
		group.updateOrbits();
		satSet.setColorScheme(ColorScheme.group);
	};

	static clearSelect = () => {
		Groups.selectedGroup = [];
		satSet.setColorScheme(ColorScheme.default);
	};

	static init = () => {
		Object.entries(initializeGroups()).forEach(([groupName, groupData]) => {
			Groups[groupName] = groupData;
		});
	};

	handleClick = (groupName, groupValue) => {
		this.setState({
			isClicked: true,
			isClearVisible: true,
			isMenuVisible: false,
			selectedGroupName: groupName,
		});
		selectSat(-1); // clear selected sat
		Groups.selectGroup(groupValue);

		searchBox.fillResultBox(groupValue.satellites, '');
	};

	handleMouseOver = (groupName, groupValue) => {
		this.setState({ isClicked: false });
		Groups.selectGroup(groupValue);
	};

	handleClearClick = () => {
		this.setState({
			isClearVisible: false,
			selectedGroupName: null,
		});
		Groups.clearSelect();
	};

	handleClearMouseOver = () => {
		this.setState({ isClicked: false });
		Groups.clearSelect();
	};

	handleMenuMouseLeave = () => {
		if (!this.state.isClicked) {
			if (searchBox.isResultBoxOpen()) {
				Groups.selectGroup(searchBox.getLastResultGroup());
			} else {
				Groups.clearSelect();
			}
		}
		this.setState({
			isMenuVisible: false,
		});
	};

	handleButtonMouseOver = () => {
		this.setState({
			isMenuVisible: true,
		});
	};

	render() {
		return (
			<div id="menu-groups" className="menu-item">
				<div
					className="menu-title"
					onMouseOver={this.handleButtonMouseOver}
				>
					Launch site{' '}
					{this.state.selectedGroupName
						? `(${this.state.selectedGroupName})`
						: ''}
				</div>
				{this.state.isMenuVisible && (
					<ul
						id="groups-display"
						className="dropdown submenu__new"
						onMouseLeave={this.handleMenuMouseLeave}
					>
						{this.state.isClearVisible && (
							<li
								className="clear-option"
								onClick={this.handleClearClick}
								onMouseOver={this.handleClearMouseOver}
								key="clear"
							>
								Clear
							</li>
						)}
						{Object.entries(this.props.groups).map(
							([groupName, groupValue]) => (
								<li
									key={groupName}
									onMouseOver={() =>
										this.handleMouseOver(
											groupName,
											groupValue
										)
									}
									onClick={() =>
										this.handleClick(groupName, groupValue)
									}
								>
									{groupName}
								</li>
							)
						)}
					</ul>
				)}
			</div>
		);
	}
}
