import React from 'react';
import styles from './InfoBox.module.scss';

const R2D = 180 / Math.PI;

export default ({ satellite }) => (
	<div id="sat-infobox" className={styles.infoBox}>
		<ul>
			<li className={styles.header}>Object Details</li>
			<li>
				<div className={styles.label}>Object Name</div>
				<div className={styles.data}>{satellite['OBJECT NAME']}</div>
			</li>
			<li>
				<div className={styles.label}>COSPAR</div>
				<div className={styles.data}>{satellite.COSPAR}</div>
			</li>
			<li>
				<div className={styles.label}>DISCOS</div>
				<div className={styles.data}>{satellite.DISCOS}</div>
			</li>
			<li>
				<div className={styles.label}>SATNO</div>
				<div className={styles.data}>{satellite.SATNO}</div>
			</li>
			<li>
				<div className={styles.label}>Mission type</div>
				<div className={styles.data}>{satellite['MISSION TYPE']}</div>
			</li>
			<li>
				<div className={styles.label}>Operators</div>
				<div className={styles.data}>{satellite.OPERATORS}</div>
			</li>
			<li>
				<div className={styles.label}>Launch Date</div>
				<div className={styles.data}>{satellite['LAUNCH DATE']}</div>
			</li>
			<li>
				<div className={styles.label}>Launch Site</div>
				<div className={styles.data}>{satellite['LAUNCH SITE']}</div>
			</li>
			<li>
				<div className={styles.label}>Mass</div>
				<div className={styles.data}>{satellite.MASS}</div>
			</li>
			<li>
				<div className={styles.label}>Dimensions (m)</div>
				<div className={styles.data}>{satellite['DIMENSIONS (m)']}</div>
			</li>
			<li>
				<div className={styles.label}>Last Contact</div>
				<div className={styles.data}>{satellite['LAST CONTACT']}</div>
			</li>

			<li className={styles.header}>Orbital parameters</li>
			<li>
				<div className={styles.label}>Regime</div>
				<div className={styles.data}>{satellite.REGIME}</div>
			</li>
			<li>
				<div className={styles.label}>Perigee</div>
				<div className={styles.data}>{`${satellite.perigee.toFixed(
					0
				)} km`}</div>
			</li>
			<li>
				<div className={styles.label}>Apogee</div>
				<div className={styles.data}>{`${satellite.apogee.toFixed(
					0
				)} km`}</div>
			</li>
			<li>
				<div className={styles.label}>Inclination</div>
				<div className={styles.data}>{`${(
					satellite.inclination * R2D
				).toFixed(2)}°`}</div>
			</li>
			<li>
				<div className={styles.label}>Altitude</div>
				<div className={styles.data}>{`${satellite.altitude.toFixed(
					2
				)} km`}</div>
			</li>
			<li>
				<div className={styles.label}>Velocity</div>
				<div className={styles.data}>{`${satellite.velocity.toFixed(
					2
				)} km/h`}</div>
			</li>
			<li>
				<div className={styles.label}>Period</div>
				<div className={styles.data}>{`${satellite.period.toFixed(
					2
				)} min`}</div>
			</li>
			<li>
				<div className={styles.label}>Epoch</div>
				<div className={styles.data}>???</div>
			</li>
			<li>
				<div className={styles.label}>Collision Probability</div>
				<div className={styles.data}>???</div>
			</li>
			<li>
				<div className={styles.label}>Predicted Decay</div>
				<div className={styles.data}>{satellite['DECAY (CE) *']}</div>
			</li>

			<li className={styles.header}>Heritage properties</li>
			<li>
				<div className={styles.heritageLabel}>Description</div>
				<div className={styles.heritageData}>{`${
					satellite.DESCRIPTION /*{.substr(
					0,
					DESCRIPTION_LENGTH
				)}${
					satellite.DESCRIPTION.length > DESCRIPTION_LENGTH
						? '...'
						: ''
				}*/
				}`}</div>
			</li>
			<li>
				<div className={styles.heritageLabel}>
					Heritage Significance
				</div>
				<div className={styles.heritageData}>
					{satellite['HERITAGE SIGNIFICANCE']}
				</div>
			</li>
			<li>
				<div className={styles.heritageLabel}>Condition Report</div>
				<div className={styles.heritageData}>
					{satellite['CONDITION REPORT']}
				</div>
			</li>
			<li>
				<div className={styles.heritageLabel}>Hazardous matter</div>
				<div className={styles.heritageData}>
					{satellite['HAZARDOUS MATTER']}
				</div>
			</li>
			<li>
				<div className={styles.heritageLabel}>Associated Items</div>
				<div className={styles.heritageData}>
					{satellite['ASSOCIATED ITEM(S)']}
				</div>
			</li>
			<li className={styles.header}>References</li>
			{satellite['AGENCY/ NSSDC'] !== 'N/A' && (
				<li>
					<div className={styles.heritageData}>
						<a
							href={satellite['AGENCY/ NSSDC']}
							target="_blank"
							rel="noopener noreferrer"
						>
							Agency / NSDDC
						</a>
					</div>
				</li>
			)}
			{satellite.WIKIPEDIA !== 'N/A' && (
				<li>
					<div className={styles.heritageData}>
						<a
							href={satellite.WIKIPEDIA}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.link}
						>
							Wikipedia
						</a>
					</div>
				</li>
			)}
			{satellite.ASTRONAUTIX !== 'N/A' && (
				<li>
					<div className={styles.heritageData}>
						<a
							href={satellite.ASTRONAUTIX}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.link}
						>
							Astronautix
						</a>
					</div>
				</li>
			)}
			{satellite["GUNTER'S / OTHERS"] !== 'N/A' && (
				<li>
					<div className={styles.heritageData}>
						<a
							href={satellite["GUNTER'S / OTHERS"]}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.link}
						>
							Gunter's / Others
						</a>
					</div>
				</li>
			)}
		</ul>
	</div>
);
