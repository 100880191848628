import React from 'react';

export default () => (
	<div className="references-box left-panel">
		<div className="box-header">References</div>
		<ul className="references">
			<li>
				Ball A, Garry JRC., Lorenz RD & Kerzhanovich VV (2007) Planetary
				Landers and Space Probes. New York; Cambridge University Press
				(ISBN 9780511284618).
			</li>
			<li>
				Barhorst L, Tilley S & Bassa C (2019) Satlist; updated R.A.E.
				Table of Earth satellites repository. Satlist website. Retrieved
				on 1 February 2019, Available at http://www.satlist.nl/
			</li>
			<li>
				Burra Charter (2013) The Burra Charter: The Australia ICOMOS
				Charter for Places of Cultural Significance, 2013. Australia
				ICOMOS Bura Charter. Retrieved on 14 February 2018, Available at
				https://australia.icomos.org/wp-content/uploads/The-Burra-Charter-2013-Adopted-31.10.2013.pdf
			</li>
			<li>
				Darrin A & O’Leary B (Eds.), The Handbook of Space Engineering,
				Archaeology, and Heritage. Boca Raton: CRC Press (DOI:
				10.1201/9781420084320).
			</li>
			<li>
				European Space Operations Centre (2018) Space Debris by the
				Numbers. European Space Agency; Space Debris. Retrieved on 3
				September 2018, Available at
				https://www.esa.int/Our_Activities/Operations/Space_Debris/Space_debris_by_the_numbers
			</li>
			<li>
				Fessenden M (2014) The International Space Station Just Avoided
				a Collision With Space Junk. Smithsonian Institute website.
				Retrieved on 3 September 2018, Available at
				https://www.smithsonianmag.com/smart-news/international-space-station-just-avoided-gravity-disaster-180953265/
			</li>
			<li>
				Fischer J (2019) Our Space Heritage 1960 - 2000.
				Hughesscgheritage website. Retrieved on 2 February 2019,
				Available at
				http://www.hughesscgheritage.com/category/technology/
			</li>
			<li>
				Google Productions (2009) Google Earth; Moon. Available (for
				download) at
				https://www.google.com/earth/download/gep/agree.html
			</li>
			<li>
				Gorman A (2009) Heritage of Earth orbit: orbital debris – its
				mitigation and cultural heritage in Handbook of Space
				Engineering, Archaeology and Heritage (Eds. Ann Garrison Darrin
				and Beth Laura O’Leary). CRC Press (ISBN: 9781420084313).
			</li>
			<li>
				Hollingham R (2018) The ambitious proposal to create a space
				‘museum’ in orbit. BBC Future. Retrieved on 19 September 2018,
				Available at
				http://www.bbc.com/future/story/20180411-the-space-museum-floating-in-orbit
			</li>
			<li>
				Huntress WT, Moroz VI & Shevalev IL (2003) Lunar and Planetary
				Robotic Exploration Missions in the 20th Century. Netherlands:
				Kluwer Academic Publishers Space Science Reviews 107, 541-649
				(DOI: 10.1023/A:1026172301375).
			</li>
			<li>
				IADC [Inter-Agency Space Debris Coordination Committee] (2007)
				IADC Space Debris Mitigation Guidelines. IADC-Online. Retrieved
				on 26 January 2016, Available at
				https://www.iadc-online.org/Documents/IADC-2002-01,%20IADC%20Space%20Debris%20Guidelines,%20Revision%201.pdf
			</li>
			<li>
				Johnston R (2018) Deep Space Probes and Other Manmade Objects
				Beyond Near Earth Space. Johnston Archive.net. Retrieved on 11
				June 2019, Available at
				http://www.johnstonsarchive.net/astro/awrjp493.html
			</li>
			<li>
				Jorgensen K, Rivkin A, Binzel RP, Whitely R, Hergenrother C,
				Chodas P, Chesley S & Vilas F (2003) Observations of J002E3:
				possible discovery of an Apollo rocket body in AAS/Division for
				Planetary Sciences Meeting Abstracts #35, (Monterey, CA:
				Bulletin of the American Astronomical Society 981).
			</li>
			<li>
				Kessler DJ & Cour-Palais BG (1978) Collision frequency of
				artificial satellites: The creation of a debris belt. Journal of
				Geophysical Research, Vol 83, Issue A6 (doi:
				10.1029/JA083iA06p02637).
			</li>
			<li>
				Leonard D (2018) China Outlines Two-Phase Chang’e 4 Moon Landing
				Mission. Space.com. Retrieved on 19 September 2018, Available at
				https://www.space.com/40002-china-outlines-change-4-moon-mission.html
			</li>
			<li>
				LROC Science Operations Team (2016) Coordinates of Lunar
				Anthropogenic Targets. Arizona State University. Retrieved on 1
				July 2017, Available at
				http://lroc.sese.asu.edu/files/DOCS/2016_LROC_Coordinates_of_Human_Features.pdf
			</li>
			<li>
				Merton ED & Colvin TR (2000) Lunar coordinates in the regions of
				the Apollo landers. Journal of Geophysical Research Vol. 105,
				No. E8 (pp. 20,277 – 20,280). Retrieved on 16 July 2018,
				Available at
				https://agupubs.onlinelibrary.wiley.com/doi/pdf/10.1029/1999JE001165
			</li>
			<li>
				Mitchell DP (2008) Lunar Impact. Mental Landscapes website.
				Retrieved on 15 September 2018, Available at
				http://mentallandscape.com/L_Luna2.htm
			</li>
			<li>
				Molnár, L (2010) Miklós Lovas and the impact of Luna-2. Pulis
				Space website. Retrieved on 15 September 2018, Available at
				http://www.pulispace.com/education/space-moon-and-the-hungarians/75-miklos-lovas-and-the-impact-of-luna-2
			</li>
			<li>
				Moon M (2015) Space junk collision scare forced ISS crew to
				evacuate. Engadget. Retrieved on 3 September 2018, Available at
				https://www.engadget.com/2015/07/17/space-junk-collision-scare-forced-iss-crew-to-evacuate/
			</li>
			<li>
				NASA History Program Office (2012) Catalogue of Manmade Material
				on the Moon. NASA history archives. Retrieved on 14 Sep 2016,
				Available at
				https://history.nasa.gov/FINAL%20Catalogue%20of%20Manmade%20Material%20on%20the%20Moon.pdf
			</li>
			<li>
				NASA (1967) Technical Report 32-1177: Surveyor III Mission
				Report. California; Jet Propulsion Laboratory publications.
			</li>
			<li>
				NASA (2018a) Advanced Exploration Systems: Resource Prospector.
				NASA official website. Retrieved on 19 September 2018, Available
				at https://www.nasa.gov/resource-prospector
			</li>
			<li>
				{' '}
				NASA (2018b) Space Science Data Coordinated Archive. Retrieved
				(multiple accesses) on 1 August 2018, Available at
				https://nssdc.gsfc.nasa.gov/nmc/
			</li>
			<li>
				NASA (2019a) Extravehicle Activity. NASA official website.
				Retrieved on 23/04/2019, Available at
				https://history.nasa.gov/SP-4029/Apollo_18-30_Extravehicular_Activity.htm
			</li>
			<li>
				NASA (2019b) Apollo 10 Timeline. NASA official website.
				Retrieved on 23/04/2019, Available at
				https://history.nasa.gov/SP-4029/Apollo_10i_Timeline.htm
			</li>
			<li>
				Of Rohn (2019) Space Exploration History. Github repository.
				Retrieved on 23/04/2019, Available at
				https://ofrohn.github.io/seh-doc/list-missions.html
			</li>
			<li>
				O’Leary BL & Capelotti PJ (eds.) (2015) Archaeology and Heritage
				of the Human Movement into Space. Switzerland: Springer
				International Publishing (ISBN: 9783319078663).
			</li>
			<li>
				Pultarova T (2018) 1st Satellite Built to Harpoon Space Junk for
				Disposal Begins Test Flight. Space.com. Retrieved on 20 August
				2018, Available at
				https://www.space.com/40960-removedebris-space-junk-cleanup-test-flight.html
			</li>
			<li>
				Quast P (2018) A profile of humanity: The cultural signature of
				Earth’s inhabitants beyond the atmosphere. International Journal
				of Astrobiology. 10.1017/S1473550418000290
			</li>
			<li>
				Reeves R (1994) The Superpower Space Race: An Explosive Rivalry
				through the Solar System. Plenum Press.
			</li>
			<li>
				Sagdeev RZ & Zakharov AV (1990) A brief history of the
				expedition to Phobos. Moscow; Space Research Institute, USSR
				Academy of Sciences. Pis'ma Astron. Zh. 16, 293-301.
			</li>
			<li>
				Space.com Staff (2011) Space Station Crew Avoids Close Shave
				with Space Junk. Space.com. Retrieved on 3 September 2018,
				Available at
				https://www.space.com/13727-space-station-crew-safe-space-junk.html
			</li>
			<li>
				Stooke P (2015) Finding the Surveyor retro rockets on the Moon.
				Planetary Society.org. Retrieved on 7 September 2018, Available
				at
				http://www.planetary.org/blogs/guest-blogs/2015/0915-finding-the-surveyor-retro-rockets-on-the-moon.html
			</li>
			<li>
				Stooke P (2018a) Spacecraft locations on the Moon – extract from
				publication; Stooke P (2008) The International Atlas of Lunar
				Exploration. Cambridge University Press. Data extract acquired
				via personal correspondence with the author, 12 September 2018.
			</li>
			<li>
				Stooke P (2018b) Mars Mission Data – extract from publication;
				Stooke, P (2016) The International Atlas of Mars Exploration -
				Vol. 2. Cambridge University Press. Data extract acquired via
				personal correspondence with the author, 25 November 2018.
			</li>
			<li>
				Tedeschi D (2017) Preserving Historical Sites on the Moon.
				Smithsonian Air & Space Magazine (online). Retrieved on 19
				September 2018, Available at
				https://www.airspacemag.com/as-interview/michelle-hanlon-180964762/
			</li>
			<li>
				UK Space Agency (2018) Space junk mission deploys from the
				International Space Station. Gov.uk website. Retrieved on 3
				September 2018, Available at
				https://www.gov.uk/government/news/space-junk-mission-deploys-from-the-international-space-station
			</li>
			<li>
				Wall M (2016) SpaceX’s Mars Colon Plan: By the Numbers.
				Space.com. Retrieved on 19 September 2018, Available at
				https://www.space.com/34234-spacex-mars-colony-plan-by-the-numbers.html
			</li>
			<li>
				Wall M (2018) Moon Rush: These Companies Have Big Plans for
				Lunar Exploration. Space.com. Retrieved on 19 September 2018,
				Available at
				https://www.space.com/39398-moon-rush-private-lunar-landings-future.html
			</li>
		</ul>
	</div>
);
