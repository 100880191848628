import React from 'react';

export default () => (
	<div className="legend-box left-panel">
		<div className="box-header">Legend</div>
		<ul id="legend">
			<li>
				<div className="dot red" />
				Low Earth orbit
			</li>
			<li>
				<div className="dot green" />
				Medium Earth orbit
			</li>
			<li>
				<div className="dot purple" />
				Geostationary orbit
			</li>
			<li>
				<div className="dot cyan" />
				Geosynchronous orbit
			</li>
			<li>
				<div className="dot yellow" />
				Elliptical/Molniya orbit
			</li>
		</ul>
		<ul className="controls-info">
			<li>Left/Right click and drag to rotate camera</li>
			<li> Mousewheel to scroll</li>
			<li>Left click to select an object</li>
		</ul>
	</div>
);
