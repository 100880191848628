/* eslint import/no-webpack-loader-syntax: off */
import $ from 'jquery';
import { mat4, vec3, vec4 } from 'gl-matrix';
import pickFragment from '!!webpack-glsl-loader!../shaders/pick-fragment.glsl';
import pickVertex from '!!webpack-glsl-loader!../shaders/pick-vertex.glsl';
import earth from './earth';
import moon from './moon';
import mars from './mars';
import venus from './venus';
import mercury from './mercury';
import ColorScheme from './colorScheme';
import satSet from './sat';
import orbitDisplay from './orbitDisplay';
import Line from './line';
import Groups, { initializeGroups } from '../Groups';
import searchBox from './searchBox';
import earthData from '../data/earth';
import moonData from '../data/moon';
import marsData from '../data/mars';

export let gl;

let camYaw = 0;
let camPitch = 0.5;

let camYawTarget = 0;
let camPitchTarget = 0;
let camSnapMode = false;
let camZoomSnappedOnSat = false;
let camAngleSnappedOnSat = false;

let zoomLevel = 0.5;
let zoomTarget = 0.5;
const ZOOM_EXP = 3;
const DIST_MIN = 6400;
const DIST_MAX = 200000;

let camPitchSpeed = 0;
let camYawSpeed = 0;

let pickFb;
let pickTex;
let pickColorBuf;

let pMatrix = mat4.create();
let camMatrix = mat4.create();

export let selectedSat = -1;

let mouseX = 0;
let mouseY = 0;
let mouseSat = -1;

let dragPoint = [0, 0, 0];
let screenDragPoint = [0, 0];
let dragStartPitch = 0;
let dragStartYaw = 0;
let isDragging = false;
let dragHasMoved = false;

let initialRotation = true;
const initialRotSpeed = 0.000075;

let debugLine;
let debugLine2;
let debugLine3;

let activePlanet = earth;

export function init() {
	let isResizing = false;

	$(window).resize(function () {
		if (!isResizing) {
			window.setTimeout(function () {
				isResizing = false;
				webGlInit();
			}, 500);
		}
		isResizing = true;
	});

	webGlInit();
	ColorScheme.init();

	const canvasEl = document.getElementById('canvas');

	$(canvasEl).on('touchmove', function (evt) {
		evt.preventDefault();
		if (isDragging) {
			dragHasMoved = true;
			camAngleSnappedOnSat = false;
			camZoomSnappedOnSat = false;
		}
		mouseX = evt.originalEvent.touches[0].clientX;
		mouseY = evt.originalEvent.touches[0].clientY;
	});

	$(canvasEl).mousemove(function (evt) {
		if (isDragging) {
			dragHasMoved = true;
			camAngleSnappedOnSat = false;
			camZoomSnappedOnSat = false;
		}
		mouseX = evt.clientX;
		mouseY = evt.clientY;
	});

	$(canvasEl).on('wheel', function (evt) {
		let delta = evt.originalEvent.deltaY;
		if (evt.originalEvent.deltaMode === 1) {
			delta *= 33.3333333;
		}
		zoomTarget += delta * 0.0002;
		if (zoomTarget > 1) zoomTarget = 1;
		if (zoomTarget < 0) zoomTarget = 0;
		initialRotation = false;
		camZoomSnappedOnSat = false;
	});

	$(canvasEl).click(function (evt) {});

	$(canvasEl).contextmenu(function () {
		return false; // stop right-click menu
	});

	$(canvasEl).mousedown(function (evt) {
		//   if(evt.which === 3) {//RMB
		dragPoint = getEarthScreenPoint(evt.clientX, evt.clientY);
		screenDragPoint = [evt.clientX, evt.clientY];
		dragStartPitch = camPitch;
		dragStartYaw = camYaw;
		//   debugLine.set(dragPoint, getCamPos());
		isDragging = true;
		camSnapMode = false;
		initialRotation = false;
		//   }
	});

	$(canvasEl).on('touchstart', function (evt) {
		const x = evt.originalEvent.touches[0].clientX;
		const y = evt.originalEvent.touches[0].clientY;
		dragPoint = getEarthScreenPoint(x, y);
		screenDragPoint = [x, y];
		dragStartPitch = camPitch;
		dragStartYaw = camYaw;
		//   debugLine.set(dragPoint, getCamPos());
		isDragging = true;
		camSnapMode = false;
		initialRotation = false;
	});

	$(canvasEl).mouseup(function (evt) {
		//   if(evt.which === 3) {//RMB
		if (!dragHasMoved) {
			const clickedSat = getSatIdFromCoord(evt.clientX, evt.clientY);
			if (clickedSat === -1) window.closeLeftPanel();
			selectSat(clickedSat);
		}
		dragHasMoved = false;
		isDragging = false;
		initialRotation = false;
		//    }
	});

	$(canvasEl).on('touchend', function (evt) {
		dragHasMoved = false;
		isDragging = false;
		initialRotation = false;
	});

	$('.menu-item').mouseover(function (evt) {
		$(this).children('.submenu').css({
			display: 'block',
		});
	});

	$('.menu-item').mouseout(function (evt) {
		$(this).children('.submenu').css({
			display: 'none',
		});
	});

	$('#zoom-in').click(function () {
		zoomTarget -= 0.04;
		if (zoomTarget < 0) zoomTarget = 0;
		initialRotation = false;
		camZoomSnappedOnSat = false;
	});

	$('#zoom-out').click(function () {
		zoomTarget += 0.04;
		if (zoomTarget > 1) zoomTarget = 1;
		initialRotation = false;
		camZoomSnappedOnSat = false;
	});
	//   debugContext = $('#debug-canvas')[0].getContext('2d');
	//   debugImageData = debugContext.createImageData(debugContext.canvas.width, debugContext.canvas.height);
	drawLoop(); // kick off the animationFrame()s
}

export function drawPlanet(planet) {
	fadeOutCanvas();
	switch (planet) {
		case 'MOON': {
			initMoon();
			break;
		}
		case 'MARS':
			initMars();
			break;

		case 'VENUS':
			initVenus();
			break;

		case 'MERCURY':
			initMercury();
			break;

		case 'EARTH':
		default: {
			initEarth();
			break;
		}
	}
}

export function initEarth() {
	earth.init();
	activePlanet = earth;
	zoomLevel = 0.6;
	zoomTarget = 0.5;
	initPlanet(earthData);
}

export function initMoon() {
	moon.init();
	activePlanet = moon;
	zoomLevel = 0.35;
	zoomTarget = 0.25;
	initPlanet(moonData);
}

export function initMars() {
	mars.init();
	activePlanet = mars;
	zoomLevel = 0.5;
	zoomTarget = 0.4;
	initPlanet(marsData);
}

export function initVenus() {
	venus.init();
	activePlanet = venus;
	zoomLevel = 0.6;
	zoomTarget = 0.5;
	initPlanet(marsData);
}

export function initMercury() {
	mercury.init();
	activePlanet = mercury;
	zoomLevel = 0.55;
	zoomTarget = 0.45;
	initPlanet(marsData);
}

function initPlanet(data) {
	initialRotation = true;

	satSet.init(data, function (satData) {
		orbitDisplay.init();
		Groups.init();
		initializeGroups();
		console.log('A');
		searchBox.init(satData);

		debugLine = new Line();
		debugLine2 = new Line();
		debugLine3 = new Line();
	});

	satSet.onCruncherReady(function (satData) {
		// do querystring stuff
		const queryStr = window.location.search.substring(1);
		const params = queryStr.split('&');
		for (let i = 0; i < params.length; i++) {
			const key = params[i].split('=')[0];
			const val = params[i].split('=')[1];
			if (key === 'intldes') {
				const urlSatId = satSet.getIdFromIntlDes(val.toUpperCase());
				selectSat(urlSatId || -1);
			} else if (key === 'search') {
				searchBox.doSearch(val);
				$('#search').val(val);
			}
		}
		if (satData) searchBox.init(satData);
	});
}

export function selectSat(satId) {
	selectedSat = satId;
	if (satId === -1) {
		window.selectSatellite(null);
		orbitDisplay.clearSelectOrbit();
	} else {
		camZoomSnappedOnSat = true;
		camAngleSnappedOnSat = true;

		satSet.selectSat(satId);
		//   camSnapToSat(satId);
		const sat = satSet.getSat(satId);
		if (!sat) return;
		orbitDisplay.setSelectOrbit(satId);

		window.selectSatellite(satId);
	}
	updateUrl();
}

export function browserUnsupported() {
	$('#canvas-holder').hide();
	$('#no-webgl').css('display', 'block');
}

function webGlInit() {
	const can = $('#canvas')[0];

	can.width = window.innerWidth;
	can.height = window.innerHeight;

	gl =
		can.getContext('webgl', { alpha: false }) ||
		can.getContext('experimental-webgl', { alpha: false });
	if (!gl) {
		browserUnsupported();
	}

	gl.viewport(0, 0, can.width, can.height);

	gl.enable(gl.DEPTH_TEST);
	gl.enable(0x8642); // enable point sprites(?!) This might get browsers with
	// underlying OpenGL to behave
	// although it's not technically a part of the WebGL standard

	window.shaderData = pickFragment;

	const pFragShader = gl.createShader(gl.FRAGMENT_SHADER);
	gl.shaderSource(pFragShader, pickFragment);
	gl.compileShader(pFragShader);

	const pVertShader = gl.createShader(gl.VERTEX_SHADER);
	gl.shaderSource(pVertShader, pickVertex);
	gl.compileShader(pVertShader);

	const pickShaderProgram = gl.createProgram();
	gl.attachShader(pickShaderProgram, pVertShader);
	gl.attachShader(pickShaderProgram, pFragShader);
	gl.linkProgram(pickShaderProgram);

	pickShaderProgram.aPos = gl.getAttribLocation(pickShaderProgram, 'aPos');
	pickShaderProgram.aColor = gl.getAttribLocation(
		pickShaderProgram,
		'aColor'
	);
	pickShaderProgram.aPickable = gl.getAttribLocation(
		pickShaderProgram,
		'aPickable'
	);
	pickShaderProgram.uCamMatrix = gl.getUniformLocation(
		pickShaderProgram,
		'uCamMatrix'
	);
	pickShaderProgram.uMvMatrix = gl.getUniformLocation(
		pickShaderProgram,
		'uMvMatrix'
	);
	pickShaderProgram.uPMatrix = gl.getUniformLocation(
		pickShaderProgram,
		'uPMatrix'
	);

	gl.pickShaderProgram = pickShaderProgram;

	pickFb = gl.createFramebuffer();
	gl.bindFramebuffer(gl.FRAMEBUFFER, pickFb);

	pickTex = gl.createTexture();
	gl.bindTexture(gl.TEXTURE_2D, pickTex);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE); // makes clearing work
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
	gl.texImage2D(
		gl.TEXTURE_2D,
		0,
		gl.RGBA,
		gl.drawingBufferWidth,
		gl.drawingBufferHeight,
		0,
		gl.RGBA,
		gl.UNSIGNED_BYTE,
		null
	);

	const rb = gl.createRenderbuffer(); // create RB to store the depth buffer
	gl.bindRenderbuffer(gl.RENDERBUFFER, rb);
	gl.renderbufferStorage(
		gl.RENDERBUFFER,
		gl.DEPTH_COMPONENT16,
		gl.drawingBufferWidth,
		gl.drawingBufferHeight
	);

	gl.framebufferTexture2D(
		gl.FRAMEBUFFER,
		gl.COLOR_ATTACHMENT0,
		gl.TEXTURE_2D,
		pickTex,
		0
	);
	gl.framebufferRenderbuffer(
		gl.FRAMEBUFFER,
		gl.DEPTH_ATTACHMENT,
		gl.RENDERBUFFER,
		rb
	);

	gl.pickFb = pickFb;

	pickColorBuf = new Uint8Array(4);

	pMatrix = mat4.create();
	mat4.perspective(
		pMatrix,
		1.01,
		gl.drawingBufferWidth / gl.drawingBufferHeight,
		20.0,
		600000.0
	);
	const eciToOpenGlMat = [1, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1];
	mat4.mul(pMatrix, pMatrix, eciToOpenGlMat); // pMat = pMat * ecioglMat
}

function getCamPos() {
	const r = getCamDist();
	const z = r * Math.sin(camPitch);
	const rYaw = r * Math.cos(camPitch);
	const x = rYaw * Math.sin(camYaw);
	const y = rYaw * Math.cos(camYaw) * -1;
	return [x, y, z];
}

function unProject(mx, my) {
	const glScreenX = (mx / gl.drawingBufferWidth) * 2 - 1.0;
	const glScreenY = 1.0 - (my / gl.drawingBufferHeight) * 2;
	const screenVec = [glScreenX, glScreenY, -0.01, 1.0]; // gl screen coords

	const comboPMat = mat4.create();
	mat4.mul(comboPMat, pMatrix, camMatrix);
	const invMat = mat4.create();
	mat4.invert(invMat, comboPMat);
	const worldVec = vec4.create();
	vec4.transformMat4(worldVec, screenVec, invMat);

	return [
		worldVec[0] / worldVec[3],
		worldVec[1] / worldVec[3],
		worldVec[2] / worldVec[3],
	];
}

function getEarthScreenPoint(x, y) {
	//  var start = performance.now();

	const rayOrigin = getCamPos();
	const ptThru = unProject(x, y);

	const rayDir = vec3.create();
	vec3.subtract(rayDir, ptThru, rayOrigin); // rayDir = ptThru - rayOrigin
	vec3.normalize(rayDir, rayDir);

	const toCenterVec = vec3.create();
	vec3.scale(toCenterVec, rayOrigin, -1); // toCenter is just -camera pos because center is at [0,0,0]
	const dParallel = vec3.dot(rayDir, toCenterVec);

	const longDir = vec3.create();
	vec3.scale(longDir, rayDir, dParallel); // longDir = rayDir * distParallel
	vec3.add(ptThru, rayOrigin, longDir); // ptThru is now on the plane going through the center of sphere
	const dPerp = vec3.len(ptThru);

	const dSubSurf = Math.sqrt(6371 * 6371 - dPerp * dPerp);
	const dSurf = dParallel - dSubSurf;

	const ptSurf = vec3.create();
	vec3.scale(ptSurf, rayDir, dSurf);
	vec3.add(ptSurf, ptSurf, rayOrigin);

	// console.log('earthscreenpt: ' + (performance.now() - start) + ' ms');

	return ptSurf;
}

function getCamDist() {
	return Math.pow(zoomLevel, ZOOM_EXP) * (DIST_MAX - DIST_MIN) + DIST_MIN;
}

function camSnapToSat(satId) {
	/* this function runs every frame that a satellite is seleected. However, the user might have broken out of the
  zoom snap or angle snap. If so, don't change those targets. */

	const sat = satSet.getSat(satId);

	if (camAngleSnappedOnSat) {
		const pos = sat.position;
		const r = Math.sqrt(pos.x * pos.x + pos.y * pos.y);
		const yaw = Math.atan2(pos.y, pos.x) + Math.PI / 2;
		const pitch = Math.atan2(pos.z, r);
		camSnap(pitch, yaw);
	}

	if (camZoomSnappedOnSat) {
		const camDistTarget = sat.altitude + 6371 + 2000;
		zoomTarget = Math.pow(
			(camDistTarget - DIST_MIN) / (DIST_MAX - DIST_MIN),
			1 / ZOOM_EXP
		);
	}
}

function camSnap(pitch, yaw) {
	camPitchTarget = pitch;
	camYawTarget = normalizeAngle(yaw);
	camSnapMode = true;
}

function normalizeAngle(angle) {
	angle %= Math.PI * 2;
	if (angle > Math.PI) angle -= Math.PI * 2;
	if (angle < -Math.PI) angle += Math.PI * 2;
	return angle;
}

let oldT = new Date();
function drawLoop() {
	const newT = new Date();
	const dt = Math.min(newT - oldT, 1000);
	oldT = newT;
	const dragTarget = getEarthScreenPoint(mouseX, mouseY);
	if (isDragging) {
		if (
			Number.isNaN(dragTarget[0]) ||
			Number.isNaN(dragTarget[1]) ||
			Number.isNaN(dragTarget[2]) ||
			Number.isNaN(dragPoint[0]) ||
			Number.isNaN(dragPoint[1]) ||
			Number.isNaN(dragPoint[2])
		) {
			// random screen drag
			const xDif = screenDragPoint[0] - mouseX;
			const yDif = screenDragPoint[1] - mouseY;
			const yawTarget = dragStartYaw + xDif * 0.005;
			const pitchTarget = dragStartPitch + yDif * -0.005;
			camPitchSpeed = normalizeAngle(camPitch - pitchTarget) * -0.005;
			camYawSpeed = normalizeAngle(camYaw - yawTarget) * -0.005;
		} else {
			// earth surface point drag
			const dragPointR = Math.sqrt(
				dragPoint[0] * dragPoint[0] + dragPoint[1] * dragPoint[1]
			);
			const dragTargetR = Math.sqrt(
				dragTarget[0] * dragTarget[0] + dragTarget[1] * dragTarget[1]
			);

			const dragPointLon = Math.atan2(dragPoint[1], dragPoint[0]);
			const dragTargetLon = Math.atan2(dragTarget[1], dragTarget[0]);

			const dragPointLat = Math.atan2(dragPoint[2], dragPointR);
			const dragTargetLat = Math.atan2(dragTarget[2], dragTargetR);

			const pitchDif = dragPointLat - dragTargetLat;
			const yawDif = normalizeAngle(dragPointLon - dragTargetLon);
			camPitchSpeed = pitchDif * 0.015;
			camYawSpeed = yawDif * 0.015;
		}
		camSnapMode = false;
	} else {
		camPitchSpeed -= camPitchSpeed * dt * 0.005; // decay speeds when globe is "thrown"
		camYawSpeed -= camYawSpeed * dt * 0.005;
	}

	camPitch += camPitchSpeed * dt;
	camYaw += camYawSpeed * dt;

	if (initialRotation) {
		camYaw += initialRotSpeed * dt;
	}

	if (camSnapMode) {
		camPitch += (camPitchTarget - camPitch) * 0.003 * dt;

		const yawErr = normalizeAngle(camYawTarget - camYaw);
		camYaw += yawErr * 0.003 * dt;

		/*   if(Math.abs(camPitchTarget - camPitch) < 0.002 && Math.abs(camYawTarget - camYaw) < 0.002 && Math.abs(zoomTarget - zoomLevel) < 0.002) {
      camSnapMode = false; Stay in camSnapMode forever. Is this a good idea? dunno....
    } */
		zoomLevel += (zoomTarget - zoomLevel) * dt * 0.0025;
	} else {
		zoomLevel += (zoomTarget - zoomLevel) * dt * 0.0075;
	}

	if (camPitch > Math.PI / 2) camPitch = Math.PI / 2;
	if (camPitch < -Math.PI / 2) camPitch = -Math.PI / 2;
	// camYaw = (camYaw % (Math.PI*2));
	camYaw = normalizeAngle(camYaw);
	// console.log(camYaw * R2D);
	if (selectedSat !== -1) {
		const sat = satSet.getSat(selectedSat);
		debugLine.set(sat, [0, 0, 0]);
		camSnapToSat(selectedSat);
	}

	drawScene();
	updateHover();
	requestAnimationFrame(drawLoop);
}

function drawScene() {
	gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);
	// gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	gl.clearColor(0.0, 0.0, 0.0, 1.0);
	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
	gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	// gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);

	camMatrix = mat4.create();
	mat4.identity(camMatrix);
	mat4.translate(camMatrix, camMatrix, [0, getCamDist(), 0]);
	mat4.rotateX(camMatrix, camMatrix, camPitch);
	mat4.rotateZ(camMatrix, camMatrix, -camYaw);

	gl.useProgram(gl.pickShaderProgram);
	gl.uniformMatrix4fv(gl.pickShaderProgram.uPMatrix, false, pMatrix);
	gl.uniformMatrix4fv(gl.pickShaderProgram.camMatrix, false, camMatrix);

	gl.clearColor(0.0, 0.0, 0.0, 1.0);
	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

	if (debugLine) debugLine.draw();
	if (debugLine2) debugLine2.draw();
	if (debugLine3) debugLine3.draw();
	activePlanet.draw(pMatrix, camMatrix);
	satSet.draw(pMatrix, camMatrix);
	orbitDisplay.draw(pMatrix, camMatrix);
}

function updateHover() {
	if (searchBox.isHovering()) {
		const satId = searchBox.getHoverSat();
		const satPos = satSet.getScreenCoords(satId, pMatrix, camMatrix);
		if (!earthHitTest(satPos.x, satPos.y)) {
			hoverBoxOnSat(satId, satPos.x, satPos.y);
		} else {
			hoverBoxOnSat(-1, 0, 0);
		}
	} else {
		mouseSat = getSatIdFromCoord(mouseX, mouseY);
		if (mouseSat !== -1) {
			orbitDisplay.setHoverOrbit(mouseSat);
		} else {
			orbitDisplay.clearHoverOrbit();
		}
		satSet.setHover(mouseSat);
		hoverBoxOnSat(mouseSat, mouseX, mouseY);
	}
}

function hoverBoxOnSat(satId, satX, satY) {
	if (satId === -1) {
		$('#sat-hoverbox').html('(none)');
		$('#sat-hoverbox').css({ display: 'none' });
		$('#canvas').css({ cursor: 'default' });
	} else {
		try {
			//    console.log(pos);
			$('#sat-hoverbox').html(satSet.getSat(satId).OBJECT_NAME);
			$('#sat-hoverbox').css({
				display: 'block',
				position: 'absolute',
				left: satX + 20,
				top: satY - 10,
			});
			$('#canvas').css({ cursor: 'pointer' });
		} catch (e) {}
	}
}

function getSatIdFromCoord(x, y) {
	// var start = performance.now();

	gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);
	gl.readPixels(
		x,
		gl.drawingBufferHeight - y,
		1,
		1,
		gl.RGBA,
		gl.UNSIGNED_BYTE,
		pickColorBuf
	);

	const pickR = pickColorBuf[0];
	const pickG = pickColorBuf[1];
	const pickB = pickColorBuf[2];

	// console.log('picking op: ' + (performance.now() - start) + ' ms');
	return ((pickB << 16) | (pickG << 8) | pickR) - 1;
}

function earthHitTest(x, y) {
	gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);
	gl.readPixels(
		x,
		gl.drawingBufferHeight - y,
		1,
		1,
		gl.RGBA,
		gl.UNSIGNED_BYTE,
		pickColorBuf
	);

	return (
		pickColorBuf[0] === 0 && pickColorBuf[1] === 0 && pickColorBuf[2] === 0
	);
}

export function updateUrl() {
	let url = '/';
	const paramSlices = [];

	if (selectedSat !== -1) {
		paramSlices.push(`intldes=${satSet.getSat(selectedSat).intlDes}`);
	}

	const currentSearch = searchBox.getCurrentSearch();
	if (currentSearch != null) {
		paramSlices.push(`search=${currentSearch}`);
	}

	if (paramSlices.length > 0) {
		url += `?${paramSlices.join('&')}`;
	}

	window.history.replaceState(null, 'Stuff in Space', url);
}

export function fadeOutCanvas() {
	const canvas = document.getElementById('canvas');
	canvas.style.transitionDuration = '0s';
	canvas.style.opacity = '0';
}

export function fadeInCanvas() {
	const canvas = document.getElementById('canvas');
	canvas.style.transitionDuration = '1s';
	canvas.style.opacity = '1';
}
