/* eslint import/no-webpack-loader-syntax: off */
import $ from 'jquery';
import { vec4, mat4 } from 'gl-matrix';
import SatCruncherWorker from '!!worker-loader!./workers/satCruncher.worker';
import dotFragment from '!!webpack-glsl-loader!../shaders/dot-fragment.glsl';
import dotVertex from '!!webpack-glsl-loader!../shaders/dot-vertex.glsl';
import ColorScheme from './colorScheme';
import { gl, browserUnsupported } from './main';

const satSet = {};

let dotShader;

let satPosBuf;
let satColorBuf;
let pickColorBuf;
let pickableBuf;

let currentColorScheme;

let shadersReady = false;

let satPos;
let satVel;
let satAlt;

let satData;
let satExtraData;

let hoveringSat = -1;
let selectedSat = -1;

const hoverColor = [0.1, 1.0, 0.0, 1.0];
const selectedColor = [0.0, 1.0, 1.0, 1.0];

try {
	var satCruncher = new SatCruncherWorker();
} catch (E) {
	browserUnsupported();
}

let cruncherReady = false;
let lastDrawTime = 0;

let cruncherReadyCallback;

let gotExtraData = false;
satCruncher.onmessage = function (m) {
	if (!gotExtraData) {
		// store extra data that comes from crunching
		satExtraData = m.data.extraData;

		for (let i = 0; i < satSet.numSats; i++) {
			satData[i].inclination = satExtraData[i].inclination;
			satData[i].eccentricity = satExtraData[i].eccentricity;
			satData[i].raan = satExtraData[i].raan;
			satData[i].argPe = satExtraData[i].argPe;
			satData[i].meanMotion = satExtraData[i].meanMotion;

			satData[i].semiMajorAxis = satExtraData[i].semiMajorAxis;
			satData[i].semiMinorAxis = satExtraData[i].semiMinorAxis;
			satData[i].apogee = satExtraData[i].apogee;
			satData[i].perigee = satExtraData[i].perigee;
			satData[i].period = satExtraData[i].period;
		}

		gotExtraData = true;
		return;
	}

	satPos = new Float32Array(m.data.satPos);
	satVel = new Float32Array(m.data.satVel);
	satAlt = new Float32Array(m.data.satAlt);

	if (!cruncherReady) {
		satSet.setColorScheme(currentColorScheme); // force color recalc
		cruncherReady = true;
		if (cruncherReadyCallback) {
			cruncherReadyCallback(satData);
		}
	}
	const $loadCover = $('#load-cover');
	if ($loadCover.css('opacity') !== 0) {
		$loadCover.css('opacity', 0);
	}
};

satSet.init = function (data, satsReadyCallback) {
	dotShader = gl.createProgram();

	const vertShader = gl.createShader(gl.VERTEX_SHADER);
	gl.shaderSource(vertShader, dotVertex);
	gl.compileShader(vertShader);

	const fragShader = gl.createShader(gl.FRAGMENT_SHADER);
	gl.shaderSource(fragShader, dotFragment);
	gl.compileShader(fragShader);

	gl.attachShader(dotShader, vertShader);
	gl.attachShader(dotShader, fragShader);
	gl.linkProgram(dotShader);

	dotShader.aPos = gl.getAttribLocation(dotShader, 'aPos');
	dotShader.aColor = gl.getAttribLocation(dotShader, 'aColor');
	dotShader.uMvMatrix = gl.getUniformLocation(dotShader, 'uMvMatrix');
	dotShader.uCamMatrix = gl.getUniformLocation(dotShader, 'uCamMatrix');
	dotShader.uPMatrix = gl.getUniformLocation(dotShader, 'uPMatrix');

	satData = data;
	satSet.satData = satData;

	satCruncher.postMessage(satData); // kick off satCruncher

	// do some processing on our earthData response
	for (let i = 0; i < satData.length; i++) {
		if (satData[i].INTLDES) {
			let year = satData[i].INTLDES.substring(0, 2); // clean up intl des for display
			const prefix = year > 50 ? '19' : '20';
			year = prefix + year;
			const rest = satData[i].INTLDES.substring(2);
			satData[i].intlDes = `${year}-${rest}`;
		} else {
			satData[i].intlDes = '(unknown)';
		}

		satData[i].id = i;
	}

	// populate GPU mem buffers, now that we know how many sats there are

	satPosBuf = gl.createBuffer();
	satPos = new Float32Array(satData.length * 3);

	const pickColorData = [];
	pickColorBuf = gl.createBuffer();
	for (let i = 0; i < satData.length; i++) {
		const byteR = (i + 1) & 0xff;
		const byteG = ((i + 1) & 0xff00) >> 8;
		const byteB = ((i + 1) & 0xff0000) >> 16;
		pickColorData.push(byteR / 255.0);
		pickColorData.push(byteG / 255.0);
		pickColorData.push(byteB / 255.0);
	}
	gl.bindBuffer(gl.ARRAY_BUFFER, pickColorBuf);
	gl.bufferData(
		gl.ARRAY_BUFFER,
		new Float32Array(pickColorData),
		gl.STATIC_DRAW
	);

	satSet.numSats = satData.length;

	satSet.setColorScheme(ColorScheme.default);
	// satSet.setColorScheme(ColorScheme.apogee);
	//  satSet.setColorScheme(ColorScheme.velocity);

	shadersReady = true;
	if (satsReadyCallback) {
		satsReadyCallback(satData);
	}
};

satSet.setColorScheme = function (scheme) {
	currentColorScheme = scheme;
	const buffers = scheme.calculateColorBuffers();
	satColorBuf = buffers.colorBuf;
	pickableBuf = buffers.pickableBuf;
};

satSet.draw = function (pMatrix, camMatrix) {
	if (!shadersReady || !cruncherReady) return;

	const now = Date.now();
	const dt = Math.min((now - lastDrawTime) / 1000.0, 1.0);
	for (let i = 0; i < satData.length * 3; i++) {
		satPos[i] += satVel[i] * dt;
	}

	gl.useProgram(dotShader);
	gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	//   gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);

	gl.uniformMatrix4fv(dotShader.uMvMatrix, false, mat4.create());
	gl.uniformMatrix4fv(dotShader.uCamMatrix, false, camMatrix);
	gl.uniformMatrix4fv(dotShader.uPMatrix, false, pMatrix);

	gl.bindBuffer(gl.ARRAY_BUFFER, satPosBuf);
	gl.bufferData(gl.ARRAY_BUFFER, satPos, gl.STREAM_DRAW);
	gl.vertexAttribPointer(dotShader.aPos, 3, gl.FLOAT, false, 0, 0);

	gl.bindBuffer(gl.ARRAY_BUFFER, satColorBuf);
	gl.enableVertexAttribArray(dotShader.aColor);
	gl.vertexAttribPointer(dotShader.aColor, 4, gl.FLOAT, false, 0, 0);

	gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
	gl.enable(gl.BLEND);
	gl.depthMask(false);

	gl.drawArrays(gl.POINTS, 0, satData.length);

	gl.depthMask(true);
	gl.disable(gl.BLEND);

	// now pickbuffer stuff......

	gl.useProgram(gl.pickShaderProgram);
	gl.bindFramebuffer(gl.FRAMEBUFFER, gl.pickFb);
	//   gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	gl.uniformMatrix4fv(gl.pickShaderProgram.uMvMatrix, false, mat4.create());
	gl.uniformMatrix4fv(gl.pickShaderProgram.uCamMatrix, false, camMatrix);
	gl.uniformMatrix4fv(gl.pickShaderProgram.uPMatrix, false, pMatrix);

	gl.bindBuffer(gl.ARRAY_BUFFER, satPosBuf);
	gl.enableVertexAttribArray(gl.pickShaderProgram.aPos);
	gl.vertexAttribPointer(gl.pickShaderProgram.aPos, 3, gl.FLOAT, false, 0, 0);

	gl.enableVertexAttribArray(gl.pickShaderProgram.aColor);
	gl.bindBuffer(gl.ARRAY_BUFFER, pickColorBuf);
	gl.vertexAttribPointer(
		gl.pickShaderProgram.aColor,
		3,
		gl.FLOAT,
		false,
		0,
		0
	);

	gl.bindBuffer(gl.ARRAY_BUFFER, pickableBuf);
	gl.enableVertexAttribArray(gl.pickShaderProgram.aPickable);
	gl.vertexAttribPointer(
		gl.pickShaderProgram.aPickable,
		1,
		gl.FLOAT,
		false,
		0,
		0
	);

	gl.drawArrays(gl.POINTS, 0, satData.length); // draw pick

	lastDrawTime = now;
};

satSet.getSat = function (i) {
	if (!satData) return null;
	const ret = satData[i];
	if (!ret) return null;
	if (gotExtraData) {
		ret.altitude = satAlt[i];
		ret.velocity = Math.sqrt(
			satVel[i * 3] * satVel[i * 3] +
				satVel[i * 3 + 1] * satVel[i * 3 + 1] +
				satVel[i * 3 + 2] * satVel[i * 3 + 2]
		);
		ret.position = {
			x: satPos[i * 3],
			y: satPos[i * 3 + 1],
			z: satPos[i * 3 + 2],
		};
	}
	return ret;
};

satSet.getIdFromIntlDes = function (intlDes) {
	for (let i = 0; i < satData.length; i++) {
		if (satData[i].INTLDES === intlDes || satData[i].intlDes === intlDes) {
			return i;
		}
	}
	return null;
};

satSet.getScreenCoords = function (i, pMatrix, camMatrix) {
	const pos = satSet.getSat(i).position;
	const posVec4 = vec4.fromValues(pos.x, pos.y, pos.z, 1);

	vec4.transformMat4(posVec4, posVec4, camMatrix);
	vec4.transformMat4(posVec4, posVec4, pMatrix);

	const glScreenPos = {
		x: posVec4[0] / posVec4[3],
		y: posVec4[1] / posVec4[3],
		z: posVec4[2] / posVec4[3],
	};

	return {
		x: (glScreenPos.x + 1) * 0.5 * window.innerWidth,
		y: (-glScreenPos.y + 1) * 0.5 * window.innerHeight,
	};
};

satSet.searchNameRegex = function (regex) {
	const res = [];
	for (let i = 0; i < satData.length; i++) {
		if (regex.test(satData[i].OBJECT_NAME)) {
			res.push(i);
		}
	}
	return res;
};

satSet.setHover = function (i) {
	if (i === hoveringSat) return;
	gl.bindBuffer(gl.ARRAY_BUFFER, satColorBuf);
	if (hoveringSat != -1 && hoveringSat != selectedSat) {
		gl.bufferSubData(
			gl.ARRAY_BUFFER,
			hoveringSat * 4 * 4,
			new Float32Array(currentColorScheme.colorizer(hoveringSat).color)
		);
	}
	if (i != -1) {
		gl.bufferSubData(
			gl.ARRAY_BUFFER,
			i * 4 * 4,
			new Float32Array(hoverColor)
		);
	}
	hoveringSat = i;
};

satSet.selectSat = function (i) {
	if (i === selectedSat) return;
	gl.bindBuffer(gl.ARRAY_BUFFER, satColorBuf);
	if (selectedSat != -1) {
		gl.bufferSubData(
			gl.ARRAY_BUFFER,
			selectedSat * 4 * 4,
			new Float32Array(currentColorScheme.colorizer(selectedSat).color)
		);
	}
	if (i != -1) {
		gl.bufferSubData(
			gl.ARRAY_BUFFER,
			i * 4 * 4,
			new Float32Array(selectedColor)
		);
	}
	selectedSat = i;
};

satSet.onCruncherReady = function (cb) {
	cruncherReadyCallback = cb;
	if (cruncherReady) cb();
};

export default satSet;
