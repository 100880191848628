import orbitDisplay from './scripts/orbitDisplay';

export default class Group {
	satellites = [];

	constructor(satelliteIds) {
		this.satellites = satelliteIds.map((satelliteId) => ({
			satId: satelliteId,
			isIntlDes: false,
			strIndex: 0,
		}));
	}

	hasSat = (id) => {
		return this.satellites.some((satellite) => satellite.satId === id);
	};

	updateOrbits = () => {
		this.satellites.forEach((satellite) =>
			orbitDisplay.updateOrbitBuffer(satellite.satId)
		);
	};

	forEach = (callback) => {
		this.satellites.forEach((satellite) => callback(satellite.satId));
	};
}
