import $ from 'jquery';
import Groups from '../Groups';
import Group from '../Group';
import { selectSat, updateUrl, selectedSat } from './main';
import orbitDisplay from './orbitDisplay';
import satSet from './sat';

const searchBox = {};
const SEARCH_LIMIT = 200;
let satData;

let hovering = false;
let hoverSatId = -1;

let resultsOpen = false;
let lastResultGroup;

searchBox.isResultBoxOpen = function () {
	return resultsOpen;
};

searchBox.getLastResultGroup = function () {
	return lastResultGroup;
};

searchBox.getCurrentSearch = function () {
	if (resultsOpen) {
		return $('#search').val();
	}
	return null;
};

searchBox.isHovering = function () {
	return hovering;
};

searchBox.getHoverSat = function () {
	return hoverSatId;
};

searchBox.hideResults = () => {
	window.closeLeftPanel();
	Groups.clearSelect();
};

searchBox.doSearch = function (str) {
	selectSat(-1);

	if (str.length === 0 || !satData) {
		this.hideResults();
		return;
	}

	str = str.toUpperCase();

	const results = [];
	for (let i = 0; i < satData.length; i++) {
		if (satData[i].OBJECT_NAME.indexOf(str) !== -1) {
			results.push({
				isIntlDes: false,
				strIndex: satData[i].OBJECT_NAME.indexOf(str),
				satId: i,
			});
		}

		if (satData[i].intlDes.indexOf(str) !== -1) {
			results.push({
				isIntlDes: true,
				strIndex: satData[i].intlDes.indexOf(str),
				satId: i,
			});
		}
	}

	if (results.length > SEARCH_LIMIT) {
		results.length = SEARCH_LIMIT;
	}

	// make a group to hilight results
	const idList = [];
	for (let i = 0; i < results.length; i++) {
		idList.push(results[i].satId);
	}
	const dispGroup = new Group(idList);
	lastResultGroup = dispGroup;
	Groups.selectGroup(dispGroup);

	searchBox.fillResultBox(results, str);
	updateUrl();
};

searchBox.fillResultBox = function (results, searchStr) {
	let html = '';

	if (!results.length) {
		html = 'No results found';
	} else {
		for (let i = 0; i < results.length; i++) {
			const sat = satData[results[i].satId];
			html += `<div class="search-result" data-sat-id="${sat.id}">`;
			if (results[i].isIntlDes) {
				html += sat.OBJECT_NAME;
			} else {
				html += sat.OBJECT_NAME.substring(0, results[i].strIndex);
				html += '<span class="search-hilight">';
				html += sat.OBJECT_NAME.substring(
					results[i].strIndex,
					results[i].strIndex + searchStr.length
				);
				html += '</span>';
				html += sat.OBJECT_NAME.substring(
					results[i].strIndex + searchStr.length
				);
			}
			html += '<div class="search-result-intldes">';
			if (results[i].isIntlDes) {
				html += sat.intlDes.substring(0, results[i].strIndex);
				html += '<span class="search-hilight">';
				html += sat.intlDes.substring(
					results[i].strIndex,
					results[i].strIndex + searchStr.length
				);
				html += '</span>';
				html += sat.intlDes.substring(
					results[i].strIndex + searchStr.length
				);
			} else {
				html += sat.intlDes;
			}
			html += '</div></div>';
		}
	}
	window.openSearch();
	const resultBox = $('#search-results');
	resultBox[0].innerHTML = html;
	resultsOpen = true;
};

searchBox.init = function (_satData) {
	console.log('init search', _satData);
	satData = _satData;
	$('#search-results').on('click', '.search-result', function (evt) {
		const satId = $(this).data('sat-id');
		selectSat(satId);
	});

	$('#search-results').on('mouseover', '.search-result', function (evt) {
		const satId = $(this).data('sat-id');
		orbitDisplay.setHoverOrbit(satId);
		satSet.setHover(satId);

		hovering = true;
		hoverSatId = satId;
	});

	$('#search-results').mouseout(function () {
		orbitDisplay.clearHoverOrbit();
		satSet.setHover(-1);
		//    hoverBoxOnSat(-1);
		hovering = false;
	});

	$('#search').on('input', function () {
		const searchStr = $('#search').val();

		searchBox.doSearch(searchStr);
	});

	$('#all-objects-link').click(function () {
		const intldes = satSet.getSat(selectedSat).intlDes;
		const searchStr = intldes.slice(0, 8);
		searchBox.doSearch(searchStr);
		$('#search').val(searchStr);
	});
};

export default searchBox;
