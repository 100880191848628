import { jday } from 'satellite.js';

const D2R = Math.PI / 180.0;

export function currentDirection() {
	const now = new Date();
	let j = jday(
		now.getUTCFullYear(),
		now.getUTCMonth() + 1, // Note, this function requires months in range 1-12.
		now.getUTCDate(),
		now.getUTCHours(),
		now.getUTCMinutes(),
		now.getUTCSeconds()
	);
	j += now.getUTCMilliseconds() * 1.15741e-8; // days per millisecond

	return getDirection(j);
}

export function getDirection(jd) {
	const n = jd - 2451545;
	let L = 280.46 + 0.9856474 * n; // mean longitude of sun
	let g = 357.528 + 0.9856003 * n; // mean anomaly
	L %= 360.0;
	g %= 360.0;

	const ecLon = L + 1.915 * Math.sin(g * D2R) + 0.02 * Math.sin(2 * g * D2R);
	const ob = getObliquity(jd);

	const x = Math.cos(ecLon * D2R);
	const y = Math.cos(ob * D2R) * Math.sin(ecLon * D2R);
	const z = Math.sin(ob * D2R) * Math.sin(ecLon * D2R);

	return [x, y, z];
}

function getObliquity(jd) {
	const t = (jd - 2451545) / 3652500;
	const ob = // arcseconds
		84381.448 -
		4680.93 * t -
		1.55 * Math.pow(t, 2) +
		1999.25 * Math.pow(t, 3) -
		51.38 * Math.pow(t, 4) -
		249.67 * Math.pow(t, 5) -
		39.05 * Math.pow(t, 6) +
		7.12 * Math.pow(t, 7) +
		27.87 * Math.pow(t, 8) +
		5.79 * Math.pow(t, 9) +
		2.45 * Math.pow(t, 10);

	return ob / 3600.0;
}
